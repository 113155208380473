<template>
  <div class="listbox">
    <el-button type="primary" size="small" @click="addFreight">+ 添加运费模板</el-button>
    <div :class="['dataBox', freightList.length == 0 ? 'flexCenter' : '']">
      <dataNone v-if="freightList.length == 0"></dataNone>
      <div v-else class="listitem" v-for="(item, index) in freightList" :key="index">
        <div class="top">
          <div class="top-left">
            <p class="temname">模板名称：{{ item.name }}</p>
            <!-- <p class="goodsing">使用中的商品：{{ item.goodsNum }}个</p> -->
            <p class="lasttime">最后编辑时间：{{ item.update_time }}</p>
          </div>
          <div class="top-right">
            <el-button type="text" @click="edit(index)">编辑</el-button>
            <el-button type="text" @click="freightDel(item.id, index)">删除</el-button>
          </div>
        </div>
        <div class="bot">
          <div class="bot-top">
            <p class="title-method">计费方式</p>
            <div class="title-box">
              <div>
                <p>配送范围</p>
                <p>{{ item.pay_type == 1 ? '首件(个)' : '首重(克)' }}</p>
                <p>{{ item.pay_type == 1 ? '运费(元)' : '首费(元)' }}</p>
                <p>{{ item.pay_type == 1 ? '续件(个)' : '续重(克)' }}</p>
                <p>续费(元)</p>
              </div>
            </div>
            <p class="title-status">状态</p>
            <p class="title-active">是否默认</p>
          </div>
          <div class="bot-bot bot-top">
            <p v-if="item.free_shipping == 1" class="title-method">包邮</p>
            <p v-else class="title-method">{{ item.pay_type == 1 ? '计件' : '计重' }}</p>
            <div class="title-box" v-if="item.free_shipping == 1">
              <div>
                <p>
                  <span>全国统一配送</span>
                </p>
                <p>0</p>
                <p>0</p>
                <p>0</p>
                <p>0</p>
              </div>
            </div>
            <div class="title-box" v-else>
              <div v-for="(areas_infos, index2) in item.areas_infos" :key="index2">
                <p>
                  <span>{{ areas_infos.name.length > 60 && areas_infos.is_open ? areas_infos.name.slice(0, 60) + '...' : areas_infos.name }}</span>
                  <template v-if="areas_infos.name.length > 60">
                    <span v-if="areas_infos.is_open" class="zhankai" @click="areas_infos.is_open = !1">展开</span>
                    <span v-else class="shouqi" @click="areas_infos.is_open = !0">收起</span>
                  </template>
                </p>
                <p>{{ areas_infos.first_fee }}</p>
                <p>{{ areas_infos.first_amount }}</p>
                <p>{{ areas_infos.additional_fee }}</p>
                <p>{{ areas_infos.additional_amount }}</p>
              </div>
            </div>
            <p class="title-status">{{ item.is_able ? '启用' : '隐藏' }}</p>
            <p class="title-active">
              <el-switch v-model="item.is_select" @change="selectChange(item, index)"></el-switch>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Paging v-if="freightList.length != 0" :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
  </div>
</template>
<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
import dataNone from '@/components/no';
export default {
  components: {
    Paging,
    dataNone,
  },
  data() {
    return {
      // 总数据量
      total: 0,
      freightList: [],
      // 当前页
      page: 1,
      // 每页多少条数据，
      pageNum: 10,
    };
  },
  created() {
    let utils = this.$store.state.utils;
    if (utils.is_record) {
      this.page = utils.page;
      this.pageNum = utils.rows;
      utils.is_record = !1;
    }
    this.getFreightList();
  },
  methods: {
    getFreightList() {
      this.$axios
        .post(this.$api.set.freightList, {
          page: this.page,
          rows: this.pageNum,
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list;
            for (let i in list) {
              list[i].areas_infos = list[i].areas_infos || [];
              list[i].default_freight = JSON.parse(list[i].default_freight);
              list[i].update_time = getDateformat(list[i].update_time);
              list[i].is_select = list[i].is_select ? !0 : !1;
              list[i].areas_infos.unshift({
                additional_amount: list[i].default_freight.additional_amount,
                additional_fee: list[i].default_freight.additional_fee,
                first_amount: list[i].default_freight.first_amount,
                first_fee: list[i].default_freight.first_fee,
                area_detail: [{ name: '全国统一配送' }],
              });
              for (let y in list[i].areas_infos) {
                let name = '';
                list[i].areas_infos[y].area_detail.map((item) => (name += item.name + ';'));
                list[i].areas_infos[y].name = name;
                list[i].areas_infos[y].is_open = !0;
              }
            }
            console.log(list);
            this.total = res.result.total;
            this.freightList = list;
          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
            });
          }
        });
    },
    edit(i) {
      localStorage.setItem('freightInfo', JSON.stringify(this.freightList[i]));
      this.$router.push('/store/freightEdit/' + this.freightList[i].id + '/' + this.page + '/' + this.pageNum);
    },
    //删除运费
    freightDel(id, i) {
      this.$confirm('确认删除此运费模板？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.set.freightDel, {
            id: id,
          })
          .then((res) => {
            if (res.code == 0) {
              this.freightList.splice(i, 1);
              this.$message({
                message: '删除成功',
                type: 'success',
              });
            } else {
              this.$message({
                message: res.msg,
                type: 'warning',
              });
            }
          });
      });
    },
    selectChange(row, index) {
      let is_select = row.is_select;
      this.$axios.post(this.$api.set.setDefaultFreight, { id: row.id, is_select: row.is_select ? 1 : 0 }).then((res) => {
        if (res.code == 0) {
          if (res.code == 0) {
            this.freightList.map((item) => (item.is_select = !1));
            this.freightList[index].is_select = is_select ? !0 : !1;
          } else {
            this.$message({
              message: res.msg,
              type: 'warning',
            });
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'warning',
          });
        }
      });
    },
    // 分页更新数据
    updateData(val, status) {
      if (status == 0) {
        this.pageNum = val;
        this.getFreightList();
      } else {
        this.page = val;
        this.getFreightList();
      }
    },
    addFreight() {
      this.$router.push({
        path: '/store/freight',
      });
    },
  },
};
</script>

<style lang="less" scoped>
.listbox {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dataBox {
    height: calc(100% - 76px);
    overflow: hidden;
    overflow-y: auto;
    margin: 10px 0;
  }
  .dataBox::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /**/
  }
  .dataBox::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 2px;
  }
  .dataBox::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .dataBox::-webkit-scrollbar-thumb:hover {
    background: #f9f9f9;
  }
  .dataBox::-webkit-scrollbar-corner {
    background: #204754;
  }
  .listitem {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ebf0f3;
      font-size: 14px;
      padding: 10px 0 10px 20px;
      .top-left {
        display: flex;
        p {
          margin-right: 30px;
        }
        .temname {
          color: #696969;
        }
        .goodsing {
          color: #acadae;
        }
        .lasttime {
          color: #a3a3a4;
        }
      }
      .top-right {
        margin-right: 30px;
      }
    }
    .bot {
      .bot-top {
        display: flex;
        justify-content: space-between;
        background: #f9f9f9;
        margin-top: 5px;
        p {
          text-align: center;
          padding: 10px 0;
          font-size: 14px;
          color: #666666;
        }
        .title-method,
        .title-status,
        .title-active {
          width: 150px;
        }
        .title-box {
          flex: 1;
          div {
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            p {
              flex: 1;
            }
            P:nth-child(1) {
              width: 150px !important;
              text-align: left;
              .zhankai,
              .shouqi {
                color: #409eff;
                margin-left: 5px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .bot-bot {
        align-items: center;
      }
    }
  }
  .paging {
    justify-content: flex-end;
  }
}
</style>
